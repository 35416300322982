import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Badge from "@mui/material/Badge";
import useFetch from "./use-fetch";
import { useDispatch } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CommentDialog(props) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { comments, updateCommentsAsync } = useFetch();
  const unViewedComments = comments.filter(
    (comment) => comment.viewed == false
  );
  const commentList = unViewedComments.map((unViewed) =>
    Object.values(unViewed).reverse()
  );
  commentList.map((lst) => (lst[1] = true));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    unViewedComments.length > 0 && dispatch(updateCommentsAsync(commentList));
  };
  return (
    <div>
      <Badge badgeContent={unViewedComments.length} color="secondary">
        <Button
          variant="outlined"
          startIcon={<PostAddIcon />}
          color="primary"
          onClick={handleClickOpen}
          style={{
            textTransform: "none",
            marginBottom: 5,
          }}
        >
          Comments
        </Button>
      </Badge>
      <Dialog
        style={{ borderRadius: 20 }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        {props.children}
      </Dialog>
    </div>
  );
}
