import * as React from "react";
import Typography from "@mui/material/Typography";
import useFetch from "./use-fetch";

export default function StatisticsCard() {
  const { cooperators, posts } = useFetch();
  const latestYear = new Date(
    Math.max(...posts.map((elmt) => new Date(elmt.postDate)))
  ).getFullYear();
  const latestMonth = new Date(
    Math.max(...posts.map((elmt) => new Date(elmt.postDate)))
  ).getMonth();
  const latestPosts = posts.filter(
    (post) =>
      new Date(post.postDate).getMonth() === latestMonth &&
      new Date(post.postDate).getFullYear() === latestYear
  );
  const noOnMainLoan = latestPosts.filter(
    (post) => Number(post.mainLoanBal) > 0
  ).length;
  const noOnFirstBankLoan = latestPosts.filter(
    (post) => Number(post.softLoanBal) > 0
  ).length;
  const noOnWelfareLoan = latestPosts.filter(
    (post) => Number(post.welfareBal) > 0
  ).length;
  const noOnRSSLoan = latestPosts.filter(
    (post) => Number(post.rssBal) > 0
  ).length;
  const noOnBuildingLoan = latestPosts.filter(
    (post) => Number(post.buildingFundBal) > 0
  ).length;
  const noOnEssentialLoan = latestPosts.filter(
    (post) => Number(post.essentialCommodityBal) > 0
  ).length;
  const noOnBheLoanBalLoan = latestPosts.filter(
    (post) => Number(post.bheLoanBal) > 0
  ).length;

  return (
    <div
      style={{
        background: "radial-gradient(75% 25%, gray, black)",
        color: "white",
        padding: 10,
        borderRadius: 5,
      }}
    >
      <h6 style={{ margin: 0 }}>Total Number of Cooperators</h6>
      <Typography variant="body2" style={{ color: "green" }}>
        Cooperators:{" "}
        <span style={{ color: "yellow", fontLine: 30 }}>
          &nbsp;{cooperators.length}
        </span>
      </Typography>
      <h6 style={{ margin: 0 }}>Cooperators on loans:</h6>
      <Typography variant="body2" style={{ color: "green" }}>
        Main:
        <span style={{ color: "yellow", fontLine: 30 }}>
          &nbsp;{noOnMainLoan}
        </span>
        <br />
        First Bank:
        <span style={{ color: "yellow", fontLine: 30 }}>
          &nbsp;{noOnFirstBankLoan}
        </span>
        <br />
        Welfare:
        <span style={{ color: "yellow", fontLine: 30 }}>
          &nbsp;{noOnWelfareLoan}
        </span>
        <br />
        RSS:
        <span style={{ color: "yellow", fontLine: 30 }}>
          &nbsp;{noOnRSSLoan}
        </span>
        <br />
        Building Fund:
        <span style={{ color: "yellow", fontLine: 30 }}>
          &nbsp;{noOnBuildingLoan}
        </span>
        <br />
        Essential Commodity:
        <span style={{ color: "yellow", fontLine: 30 }}>
          &nbsp;{noOnEssentialLoan}
        </span>
        <br />
        Building / Housing / Equipment:
        <span style={{ color: "yellow", fontLine: 30 }}>
          &nbsp;{noOnBheLoanBalLoan}
        </span>
        <br />
      </Typography>
    </div>
  );
}
