import * as React from "react";
import Stack from "@mui/material/Stack";
import CarouselComp from "./carousel";
import "./site-style.css";
import Exco from "./exco";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
export default function Home() {
  return (
    <Stack
      sx={{
        background:
          "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%," +
          "rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%," +
          "rgba(229,255,239,1) 93%)",
      }}
    >
      <Stack
        direction="row"
        spacing={6}
        alignItems="center"
        justifyContent="space-evenly"
        sx={{
          m: 1,
        }}
      >
        <img
          src="/logo.png"
          alt="logo"
          style={{ marginRight: 10, width: "70px", height: "70px" }}
        />
        <Typography
          sx={{
            maxWidth: "400px",
            // mt: 2,
            textAlign: "center",
            fontFamily: "'Gill Sans Extabold' sans-serif",
            fontWeight: "bold",
            fontSize: 20,
            display: { xs: "none", md: "flex" },
            // alignItems: "center",
            // backgroundClip: "text",
            // fontSize: 60,
            // fontWeight: 700,
            // color: "transparent",
            // backgroundImage: "url('/c.jpg')",
          }}
        >
          NACETEM (IFE) STAFF COOPERATIVE INVESTMENT AND CREDIT SOCIETY (NSCICS)
        </Typography>
        <Typography
          sx={{
            maxWidth: "400px",
            // mt: 2,
            textAlign: "center",
            fontFamily: "'Gill Sans Extabold' sans-serif",
            fontWeight: "bold",
            fontSize: 20,
            display: { xs: "flex", sm: "flex", md: "none" },
          }}
        >
          NSCICS
        </Typography>

        <a
          href="https://coop.nacetemstaffcics.com"
          style={{
            textDecoration: "none",
            border: "solid 2px green",
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 15,
            paddingRight: 15,
            borderRadius: 5,
            color: "green",
          }}
        >
          Login
        </a>
      </Stack>
      <CarouselComp />
      <h2
        style={{
          maxWidth: 200,
          marginTop: 40,
          marginBottom: 10,
          margin: "auto",
          padding: 20,
        }}
      >
        Meet the Excos
      </h2>
      <Stack
        spacing={2}
        direction={{ xs: "column", sm: "column" }}
        alignItems="center"
        justifyContent="center"
        style={{ margin: 3 }}
      >
        <Stack
          spacing={2}
          direction={{ xs: "column", sm: "column", md: "row" }}
          alignItems="center"
          justifyContent="center"
          style={{ margin: 3 }}
        >
          <Exco imgSrc="/otunba.gif" name="President" />
          <Exco imgSrc="/funminiyi.jpeg" name="Vice President" />
        </Stack>
        {/* Second////// */}
        <Stack
          spacing={2}
          direction={{ xs: "column", sm: "column", md: "row" }}
          alignItems="center"
          justifyContent="center"
          style={{ margin: 3 }}
        >
          <Exco imgSrc="/mamaojo.jpg" name="Financial Secretary" />
          <Exco imgSrc="/ejire.jpeg" name="Secretary" />
        </Stack>
        {/* Third////// */}
        <Stack
          spacing={2}
          direction={{ xs: "column", sm: "column", md: "row" }}
          alignItems="center"
          justifyContent="center"
          style={{ margin: 3 }}
        >
          <Exco imgSrc="/Idowu.jpeg" name="Treasurer" />
          <Exco imgSrc="/odebiyi.jpeg" name="Ex Officio 1" />
        </Stack>
        <Stack
          spacing={2}
          direction={{ xs: "column", sm: "column", md: "row" }}
          alignItems="center"
          justifyContent="center"
          style={{ margin: 3 }}
        >
          <Exco imgSrc="/victoria.jpeg" name="Ex Officio 2" />
        </Stack>
      </Stack>

      <Typography
        component="body1"
        sx={{
          color: "text.secondary",
          maxWidth: 600,
          margin: "auto",
          textAlign: "center",
          my: 6,
          fontSize: 12,
        }}
      >
        &copy; {new Date().getFullYear()} NACETEM (IFE) STAFF COOPERATIVE
        INVESTMENT AND CREDIT SOCIETY (NSCICS)
      </Typography>
    </Stack>
  );
}
