import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AdminDashBoardPostHistory from "./admin-dashboard-post-history";
import PostHistoryNav from "./post-history-nav";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";

export default function PostHistoryCard() {
  return (
    <Stack
      direction="column"
      spacing={{ xs: 1, sm: 2 }}
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <AdminDashBoardPostHistory />
    </Stack>
  );
}
