import React, { Suspense } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Field, Formik, Form, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { TextField } from "formik-mui";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import useFetch from "./use-fetch";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useFeedBack, FeedBack } from "./feedback";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import NumberFormat from "react-number-format";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Badge from "@mui/material/Badge";
import EditIcon from "@mui/icons-material/Edit";
const Loan = React.lazy(() => import("./loan"));
const NoDeduction = React.lazy(() => import("./no-deduction"));
const PayPart = React.lazy(() => import("./pay-part"));
const PayUp = React.lazy(() => import("./pay-up"));
const MainLoanAuto = React.lazy(() => import("./main-loan-auto"));
const ExistingLoan = React.lazy(() => import("./existing-loan"));

const GetFormikPropsFromContext = () => {
  const { values, setFieldValue } = useFormikContext();
  const ifFalsyReturnZero = (val) => (!val || Number.isNaN(val) ? 0 : val);
  React.useEffect(() => {
    setFieldValue(
      "totalDeductions",
      ifFalsyReturnZero(Number(values.sharesCr)) +
        ifFalsyReturnZero(Number(values.savingsCr)) +
        ifFalsyReturnZero(Number(values.rssCr)) +
        ifFalsyReturnZero(Number(values.specialDepCr)) +
        ifFalsyReturnZero(Number(values.mainLoanCr)) +
        ifFalsyReturnZero(Number(values.softLoanCr)) +
        ifFalsyReturnZero(Number(values.otherLoanCr)) +
        ifFalsyReturnZero(Number(values.buildingFundCr)) +
        ifFalsyReturnZero(Number(values.essentialCommodityCr)) +
        ifFalsyReturnZero(Number(values.bheLoanCr)) +
        ifFalsyReturnZero(Number(values.adminFessChargesCr)) +
        ifFalsyReturnZero(Number(values.entranceRegFeeCr)) +
        ifFalsyReturnZero(Number(values.developmentLevyCr)) +
        ifFalsyReturnZero(Number(values.foodStuffCr)) +
        ifFalsyReturnZero(Number(values.welfareCr))
    );
  }, [values]);
  return null;
};

function PostInner({ postOp }) {
  const { openSnack, setOpenSnack, msg, setMsg, handleCloseSnackbar } =
    useFeedBack();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false); //
  const {
    toDecimal,
    formValues,
    settings,
    cooperatorName,
    loaner,
    cooperators,
    posts,
    isObjectNotEmpty,
    newPostsAsync,
    setNewLoanAutoData,
    resetNewLoanAutoData,
    updateNewMainLoanProcessing,
    updateAdditionalLoanProcessing,
    newLoanEditAsync,
    payUpAsyc,
    parPartAsync,
    updateNewMainLoanAutoremainingPeriodAsync,
    deleteAutoMainLoanRecordAsyc,
    updateFieldValue,
    clearFieldInitialValue,
    additionalLoanAsyc,
    updatePayUp,
    updateLoanAutoRemPeriodPayPartAsync,

    //////////////////
    newSoftLoanProcessing,
    newMainLoanProcessing,
    additionalLoanProcessing,
    newFoodLoanProcessing,
    newWelfareLoanProcessing,
    newBuildingLoanProcessing,
    newOtherLoanProcessing,
    //////////////////////
    payUpMainLoanProcessing,
    payUpSoftLoanProcessing,
    payUpOtherLoanProcessing,
    payUpFoodLoanProcessing,
    payUpWelfareLoanProcessing,
    payUpBuildingLoanProcessing,
    payUpEssentialLoanProcessing,
    payUpBheLoanProcessing,
    newEssentialLoanProcessing,
    newBheLoanProcessing,
    ////////////////////////
    payPartMainLoanProcessing,
    payPartOtherLoanProcessing,
    payPartSoftLoanProcessing,
    payPartFoodLoanProcessing,
    payPartWelfareLoanProcessing,
    payPartBuildingLoanProcessing,
    payPartEssentialLoanProcessing,
    payPartBheLoanProcessing,
    //////////////////////
    noDedMainLoanProcessing,
    noDedOtherLoanProcessing,
    noDedSoftLoanProcessing,
    noDedFoodLoanProcessing,
    noDedWelfareLoanProcessing,
    noDedBuildingLoanProcessing,
    noDedEssentialLoanProcessing,
    noDedBheLoanProcessing,
    //////////
    existingMainLoanProcessing,
    existingOtherLoanProcessing,
    existingSoftLoanProcessing,
    existingFoodLoanProcessing,
    existingWelfareLoanProcessing,
    existingBuildingLoanProcessing,
    existingEssentialLoanProcessing,
    existingBheLoanProcessing,
    //////////
    newSoftLoanAutoData,
    newOtherLoanAutoData,
    newFoodLoanAutoData,
    newWelfareLoanAutoData,
    newBuildingLoanAutoData,
    newMainLoanAutoData,
    newEssentialLoanAutoData,
    newBheLoanAutoData,
    updateBal,
    status,
    error,
    noDedAsync,
    setFieldInitialValue,
    editPostsAsync,
  } = useFetch();

  const handleChangePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleUpdateFieldValue = (formName, formValue) =>
    dispatch(
      updateFieldValue({
        formName,
        formValue,
      })
    );

  const numberFormat = (val) => (
    <NumberFormat
      value={Number(val)}
      displayType="text"
      thousandSeparator={true}
      prefix="₦"
    />
  );
  // const { values } = useFormikContext() ?? {};
  // React.useEffect(()=>{
  //   handleUpdateFieldValue("mainLoanCr", values ? values.mainLoanCr:222);
  //   handleUpdateFieldValue("softLoanCr", values ? values.softLoanCr:66777);
  //   console.log("values:", values)
  // },[values])

  //`loanDate`, `loanCr`, `postPrevDate`
  // function debounce(callback, wait) {
  //   let timeout;
  //   return (...args) => {
  //     clearTimeout(timeout);
  //     timeout = setTimeout(function () {
  //       callback.apply(this, args);
  //     }, wait);
  //   };
  // }
  const setNewLoanEditDataList = (newLoanEditData) => [
    newLoanEditData.cooperatorId,
    newLoanEditData.loan,
    newLoanEditData.loanOp,
    newLoanEditData.loanDate,
    newLoanEditData.loanCr,
    newLoanEditData.postPrevDate,
  ];
  // const [position, setPosition] = React.useState([0, 0]);
  // React.useEffect(() => {
  // function myFunction() {
  //   if (
  //     document.body.scrollTop > 5 ||
  //     document.documentElement.scrollTop > 5
  //   ) {
  //     console.log("Scroll Reached:", getScrollingPosition());
  //   } else {
  //     console.log("Scroll Not Reached");
  //   }
  // }

  //   function getScrollingPosition() {
  //     if (typeof window.pageYOffset != "undefined") {
  //       setPosition([window.pageXOffset, window.pageYOffset]);
  //     } else if (
  //       (typeof document.documentElement.scrollTop != "undefined" &&
  //         document.documentElement.scrollTop > 0) ||
  //       document.documentElement.scrollLeft > 0
  //     ) {
  //       setPosition([
  //         document.documentElement.scrollLeft,
  //         document.documentElement.scrollTop,
  //       ]);
  //     } else if (typeof document.body.scrollTop != "undefined") {
  //       setPosition([document.body.scrollLeft, document.body.scrollTop]);
  //     }
  //     console.log("Scroll Position:", position);
  //     return position;
  //   }
  //   window.addEventListener("onscroll", getScrollingPosition());
  //   return window.removeEventListener("onscroll", getScrollingPosition());
  // }, [position]);
  return (
    <>
      <FeedBack
        openSnack={openSnack}
        msg={msg}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Box>
        <Formik
          enableReinitialize={true}
          // validateOnChange={false}
          initialValues={{
            id: formValues.id,
            postDate: formValues.postDate,
            postParticuars: formValues.postParticuars || "",
            totalDeductions: formValues.totalDeductions,
            sharesDr: formValues.sharesDr || 0,
            sharesCr: formValues.sharesCr || 0,
            sharesBal: formValues.sharesBal,
            savingsCr: formValues.savingsCr || 0,
            savingsDr: formValues.savingsDr || 0,
            savingsBal: formValues.savingsBal,
            rssDr: formValues.rssDr || 0,
            rssCr: formValues.rssCr || 0,
            rssBal: formValues.rssBal,
            specialDepDr: formValues.specialDepDr || 0,
            specialDepCr: formValues.specialDepCr || 0,
            specialDepBal: formValues.specialDepBal,
            totalAsset:
              Number(formValues.totalAsset) +
              Number(formValues.sharesCr) +
              Number(formValues.savingsCr) +
              Number(formValues.rssCr) +
              Number(formValues.specialDepCr) -
              Number(formValues.sharesDr) -
              Number(formValues.savingsDr) -
              Number(formValues.rssDr) -
              Number(formValues.specialDepDr) -
              Number(formValues.adminFessChargesDr),
            mainLoanDr:
              additionalLoanProcessing ||
              (newMainLoanProcessing && newMainLoanAutoData !== undefined) ||
              payUpMainLoanProcessing ||
              existingMainLoanProcessing
                ? newMainLoanAutoData.loanAutoDr
                : formValues.mainLoanDr || 0,
            mainLoanCr:
              payUpMainLoanProcessing ||
              payPartMainLoanProcessing ||
              noDedMainLoanProcessing ||
              existingMainLoanProcessing
                ? newMainLoanAutoData.loanAutoCr
                : (newMainLoanProcessing &&
                    newMainLoanAutoData !== undefined) ||
                  additionalLoanProcessing
                ? 0
                : formValues.mainLoanCr || 0,
            mainLoanBal:
              additionalLoanProcessing ||
              (newMainLoanProcessing && newMainLoanAutoData !== undefined) ||
              payUpMainLoanProcessing ||
              payPartMainLoanProcessing ||
              noDedMainLoanProcessing ||
              existingMainLoanProcessing
                ? newMainLoanAutoData.loanAutoBal
                : formValues.mainLoanBal || 0,
            mainLoanInt:
              additionalLoanProcessing ||
              (newMainLoanProcessing && newMainLoanAutoData !== undefined) ||
              payUpMainLoanProcessing
                ? newMainLoanAutoData.interest
                : formValues.mainLoanInt || 0,
            mainLoanPrevBal: formValues.mainLoanPrevBal || 0,
            softLoanDr:
              (newSoftLoanProcessing && newSoftLoanAutoData !== undefined) ||
              payUpSoftLoanProcessing ||
              existingSoftLoanProcessing
                ? newSoftLoanAutoData.loanAutoDr
                : formValues.softLoanDr || 0,
            softLoanCr:
              payUpSoftLoanProcessing ||
              payPartSoftLoanProcessing ||
              noDedSoftLoanProcessing ||
              existingSoftLoanProcessing
                ? newSoftLoanAutoData.loanAutoCr
                : newSoftLoanProcessing && newSoftLoanAutoData !== undefined
                ? 0
                : Number(formValues.softLoanCr) || 0,
            softLoanBal:
              (newSoftLoanProcessing && newSoftLoanAutoData !== undefined) ||
              payUpSoftLoanProcessing ||
              payPartSoftLoanProcessing ||
              noDedSoftLoanProcessing ||
              existingSoftLoanProcessing
                ? newSoftLoanAutoData.loanAutoBal
                : formValues.softLoanBal || 0,
            softLoanInt:
              (newSoftLoanProcessing && newSoftLoanAutoData !== undefined) ||
              payUpSoftLoanProcessing
                ? newSoftLoanAutoData.interest
                : formValues.softLoanInt || 0,
            ///////////////////////// other loans begins
            otherLoanDr:
              (newOtherLoanProcessing && newOtherLoanAutoData !== undefined) ||
              payUpOtherLoanProcessing ||
              existingOtherLoanProcessing
                ? newOtherLoanAutoData.loanAutoDr
                : formValues.otherLoanDr || 0,
            otherLoanCr:
              payUpOtherLoanProcessing ||
              payPartOtherLoanProcessing ||
              noDedOtherLoanProcessing ||
              existingOtherLoanProcessing
                ? newOtherLoanAutoData.loanAutoCr
                : newOtherLoanProcessing && newOtherLoanAutoData !== undefined
                ? 0
                : formValues.otherLoanCr || 0,
            otherLoanBal:
              (newOtherLoanProcessing && newOtherLoanAutoData !== undefined) ||
              payUpOtherLoanProcessing ||
              payPartOtherLoanProcessing ||
              noDedOtherLoanProcessing ||
              existingOtherLoanProcessing
                ? newOtherLoanAutoData.loanAutoBal
                : formValues.otherLoanBal || 0,
            otherLoanInt:
              (newOtherLoanProcessing && newOtherLoanAutoData !== undefined) ||
              payUpOtherLoanProcessing
                ? newOtherLoanAutoData.interest
                : formValues.otherLoanInt || 0,
            ///////////////// food stuff
            foodStuffDr:
              (newFoodLoanProcessing && newFoodLoanAutoData !== undefined) ||
              payUpFoodLoanProcessing ||
              existingFoodLoanProcessing
                ? newFoodLoanAutoData.loanAutoDr
                : formValues.foodStuffDr || 0,
            foodStuffCr:
              payUpFoodLoanProcessing ||
              payPartFoodLoanProcessing ||
              noDedFoodLoanProcessing ||
              existingFoodLoanProcessing
                ? newFoodLoanAutoData.loanAutoCr
                : newFoodLoanProcessing && newFoodLoanAutoData !== undefined
                ? 0
                : formValues.foodStuffCr || 0,
            foodStuffBal:
              (newFoodLoanProcessing && newFoodLoanAutoData !== undefined) ||
              payUpFoodLoanProcessing ||
              payPartFoodLoanProcessing ||
              noDedFoodLoanProcessing ||
              existingFoodLoanProcessing
                ? newFoodLoanAutoData.loanAutoBal
                : formValues.foodStuffBal || 0,
            foodStuffInt:
              (newFoodLoanProcessing && newFoodLoanAutoData !== undefined) ||
              payUpFoodLoanProcessing
                ? newFoodLoanAutoData.interest
                : formValues.foodStuffInt || 0,
            welfareDr:
              (newWelfareLoanProcessing &&
                newWelfareLoanAutoData !== undefined) ||
              payUpWelfareLoanProcessing ||
              existingWelfareLoanProcessing
                ? newWelfareLoanAutoData.loanAutoDr
                : formValues.welfareDr || 0,
            welfareCr:
              payUpWelfareLoanProcessing ||
              payPartWelfareLoanProcessing ||
              noDedWelfareLoanProcessing ||
              existingWelfareLoanProcessing
                ? newWelfareLoanAutoData.loanAutoCr
                : newWelfareLoanProcessing &&
                  newWelfareLoanAutoData !== undefined
                ? 0
                : formValues.welfareCr || 0,
            welfareBal:
              (newWelfareLoanProcessing &&
                newWelfareLoanAutoData !== undefined) ||
              payUpWelfareLoanProcessing ||
              payPartWelfareLoanProcessing ||
              noDedWelfareLoanProcessing ||
              existingWelfareLoanProcessing
                ? newWelfareLoanAutoData.loanAutoBal
                : formValues.welfareBal || 0,
            welfareInt:
              (newWelfareLoanProcessing &&
                newWelfareLoanAutoData !== undefined) ||
              payUpWelfareLoanProcessing
                ? newWelfareLoanAutoData.interest
                : formValues.welfareInt || 0,

            buildingFundDr:
              (newBuildingLoanProcessing &&
                newBuildingLoanAutoData !== undefined) ||
              payUpBuildingLoanProcessing ||
              existingBuildingLoanProcessing
                ? newBuildingLoanAutoData.loanAutoDr
                : formValues.buildingFundDr || 0,
            buildingFundCr:
              payUpBuildingLoanProcessing ||
              payPartBuildingLoanProcessing ||
              noDedBuildingLoanProcessing ||
              existingBuildingLoanProcessing
                ? newBuildingLoanAutoData.loanAutoCr
                : newBuildingLoanProcessing &&
                  newBuildingLoanAutoData !== undefined
                ? 0
                : formValues.buildingFundCr || 0,
            buildingFundBal:
              (newBuildingLoanProcessing &&
                newBuildingLoanAutoData !== undefined) ||
              payUpBuildingLoanProcessing ||
              payPartBuildingLoanProcessing ||
              noDedBuildingLoanProcessing ||
              existingBuildingLoanProcessing
                ? newBuildingLoanAutoData.loanAutoBal
                : formValues.buildingFundBal || 0,
            buildingFundInt:
              (newBuildingLoanProcessing &&
                newBuildingLoanAutoData !== undefined) ||
              payUpBuildingLoanProcessing
                ? newBuildingLoanAutoData.interest
                : formValues.buildingFundInt || 0,

            essentialCommodityDr:
              (newEssentialLoanProcessing &&
                newEssentialLoanAutoData !== undefined) ||
              payUpEssentialLoanProcessing ||
              existingEssentialLoanProcessing
                ? newEssentialLoanAutoData.loanAutoDr
                : formValues.essentialCommodityDr || 0,
            essentialCommodityCr:
              payUpEssentialLoanProcessing ||
              payPartEssentialLoanProcessing ||
              noDedEssentialLoanProcessing ||
              existingEssentialLoanProcessing
                ? newEssentialLoanAutoData.loanAutoCr
                : newEssentialLoanProcessing &&
                  newEssentialLoanAutoData !== undefined
                ? 0
                : formValues.essentialCommodityCr || 0,
            essentialCommodityBal:
              (newEssentialLoanProcessing &&
                newEssentialLoanAutoData !== undefined) ||
              payUpEssentialLoanProcessing ||
              payPartEssentialLoanProcessing ||
              noDedEssentialLoanProcessing ||
              existingEssentialLoanProcessing
                ? newEssentialLoanAutoData.loanAutoBal
                : formValues.essentialCommodityBal || 0,
            essentialCommodityInt:
              (newEssentialLoanProcessing &&
                newEssentialLoanAutoData !== undefined) ||
              payUpEssentialLoanProcessing
                ? newEssentialLoanAutoData.interest
                : formValues.essentialCommodityInt || 0,
            ///////////////////////
            bheLoanDr:
              (newBheLoanProcessing && newBheLoanAutoData !== undefined) ||
              payUpBheLoanProcessing ||
              existingBheLoanProcessing
                ? newBheLoanAutoData.loanAutoDr
                : formValues.bheLoanDr || 0,
            bheLoanCr:
              payUpBheLoanProcessing ||
              payPartBheLoanProcessing ||
              noDedBheLoanProcessing ||
              existingBheLoanProcessing
                ? newBheLoanAutoData.loanAutoCr
                : newBheLoanProcessing && newBheLoanAutoData !== undefined
                ? 0
                : formValues.bheLoanCr || 0,
            bheLoanBal:
              (newBheLoanProcessing && newBheLoanAutoData !== undefined) ||
              payUpBheLoanProcessing ||
              payPartBheLoanProcessing ||
              noDedBheLoanProcessing ||
              existingBheLoanProcessing
                ? newBheLoanAutoData.loanAutoBal
                : formValues.bheLoanBal || 0,
            bheLoanInt:
              (newBheLoanProcessing && newBheLoanAutoData !== undefined) ||
              payUpBheLoanProcessing
                ? newBheLoanAutoData.interest
                : formValues.bheLoanInt || 0,
            ////////
            adminFessChargesDr: formValues.adminFessChargesDr || 0,
            adminFessChargesCr: formValues.adminFessChargesCr || 0,
            adminFessChargesBal: formValues.adminFessChargesBal || 0,
            developmentLevyCr: formValues.developmentLevyCr || 0,
            entranceRegFeeCr: formValues.entranceRegFeeCr || 0,
            postedBy: formValues.postedBy || "",
          }}
          // validationSchema={Yup.object({
          //   postDate: Yup.string().required("*Post date field is required"),
          //   postedBy: Yup.string().required("*Posted by* field is required"),
          //   names: Yup.string().required("*Name* field is required"),
          // })}
          onSubmit={async (values, { setSubmitting, setFieldValue }) => {
            setSubmitting(true);
            const postData = {
              id: formValues.id,
              postDate: values.postDate,
              postParticuars: values.postParticuars,
              totalDeductions: values.totalDeductions,
              sharesDr: values.sharesDr,
              sharesCr: values.sharesCr,
              sharesBal:
                Number(values.sharesBal) +
                Number(values.sharesCr) -
                Number(values.sharesDr),
              savingsCr: values.savingsCr,
              savingsDr: values.savingsDr,
              savingsBal:
                Number(values.savingsBal) +
                Number(values.savingsCr) -
                Number(values.savingsDr),
              rssDr: values.rssDr,
              rssCr: values.rssCr,
              rssBal:
                Number(values.rssBal) +
                Number(values.rssCr) -
                Number(values.rssDr),
              specialDepDr: values.specialDepDr,
              specialDepCr: values.specialDepCr,
              specialDepBal:
                Number(values.specialDepBal) +
                Number(values.specialDepCr) -
                Number(values.specialDepDr),
              totalAsset: values.totalAsset,
              mainLoanDr: values.mainLoanDr,
              mainLoanCr: values.mainLoanCr,
              mainLoanBal:
                Number(values.mainLoanCr) !== 0
                  ? Number(values.mainLoanBal) - Number(values.mainLoanCr)
                  : Number(values.mainLoanDr),
              mainLoanInt: values.mainLoanInt,
              softLoanDr: values.softLoanDr,
              softLoanCr: values.softLoanCr,
              softLoanBal:
                Number(values.softLoanCr) !== 0
                  ? Number(values.softLoanBal) - Number(values.softLoanCr)
                  : Number(values.softLoanDr),
              softLoanInt: values.softLoanInt,
              foodStuffCr: values.foodStuffCr,
              foodStuffDr: values.foodStuffDr,
              foodStuffBal:
                Number(values.foodStuffCr) !== 0
                  ? Number(values.foodStuffBal) - Number(values.foodStuffCr)
                  : Number(values.foodStuffDr),
              foodStuffInt: values.foodStuffInt,
              welfareCr: values.welfareCr,
              welfareDr: values.welfareDr,
              welfareBal:
                Number(values.welfareCr) !== 0
                  ? Number(values.welfareBal) - Number(values.welfareCr)
                  : Number(values.welfareDr),
              welfareInt: values.welfareInt,
              otherLoanDr: values.otherLoanDr,
              otherLoanCr: values.otherLoanCr,
              otherLoanBal:
                Number(values.otherLoanCr) !== 0
                  ? Number(values.otherLoanBal) - Number(values.otherLoanCr)
                  : Number(values.otherLoanDr),
              otherLoanInt: values.otherLoanInt,
              buildingFundDr: values.buildingFundDr,
              buildingFundCr: values.buildingFundCr,
              buildingFundBal:
                Number(values.buildingFundCr) !== 0
                  ? Number(values.buildingFundBal) -
                    Number(values.buildingFundCr)
                  : Number(values.buildingFundDr),
              buildingFundInt: values.buildingFundInt,
              essentialCommodityDr: values.essentialCommodityDr,
              essentialCommodityCr: values.essentialCommodityCr,
              essentialCommodityBal:
                Number(values.essentialCommodityCr) !== 0
                  ? Number(values.essentialCommodityBal) -
                    Number(values.essentialCommodityCr)
                  : Number(values.essentialCommodityDr),
              essentialCommodityInt: values.essentialCommodityInt,
              bheLoanDr: values.bheLoanDr,
              bheLoanCr: values.bheLoanCr,
              bheLoanBal:
                Number(values.bheLoanCr) !== 0
                  ? Number(values.bheLoanBal) - Number(values.bheLoanCr)
                  : Number(values.bheLoanDr),
              bheLoanInt: values.bheLoanInt,
              adminFessChargesDr: values.adminFessChargesDr,
              adminFessChargesCr: values.adminFessChargesCr,
              adminFessChargesBal: values.adminFessChargesBal,
              developmentLevyCr: values.developmentLevyCr,
              entranceRegFeeCr: values.entranceRegFeeCr,
              postedBy: values.postedBy,
            };

            //////////////
            //New loans being processed   ***begins
            const bulkLoanValues = [];
            (newMainLoanProcessing || existingMainLoanProcessing) &&
              newMainLoanAutoData !== undefined &&
              bulkLoanValues.push(setNewLoanEditDataList(newMainLoanAutoData));
            (newSoftLoanProcessing || existingSoftLoanProcessing) &&
              newSoftLoanAutoData !== undefined &&
              bulkLoanValues.push(setNewLoanEditDataList(newSoftLoanAutoData));
            (newFoodLoanProcessing || existingFoodLoanProcessing) &&
              newFoodLoanAutoData !== undefined &&
              bulkLoanValues.push(setNewLoanEditDataList(newFoodLoanAutoData));
            (newOtherLoanProcessing || existingOtherLoanProcessing) &&
              newOtherLoanAutoData !== undefined &&
              bulkLoanValues.push(setNewLoanEditDataList(newOtherLoanAutoData));
            (newWelfareLoanProcessing || existingWelfareLoanProcessing) &&
              newWelfareLoanAutoData !== undefined &&
              bulkLoanValues.push(
                setNewLoanEditDataList(newWelfareLoanAutoData)
              );
            (newBuildingLoanProcessing || existingBuildingLoanProcessing) &&
              newBuildingLoanAutoData !== undefined &&
              bulkLoanValues.push(
                setNewLoanEditDataList(newBuildingLoanAutoData)
              );
            (newEssentialLoanProcessing || existingEssentialLoanProcessing) &&
              newEssentialLoanAutoData !== undefined &&
              bulkLoanValues.push(
                setNewLoanEditDataList(newEssentialLoanAutoData)
              );
            (newBheLoanProcessing || existingBheLoanProcessing) &&
              newBheLoanAutoData !== undefined &&
              bulkLoanValues.push(setNewLoanEditDataList(newBheLoanAutoData));

            // additional loan being processed   ***begins
            const additionalData = [
              [
                newMainLoanAutoData.cooperatorId,
                newMainLoanAutoData.loan,
                newMainLoanAutoData.loanOp,
                newMainLoanAutoData.loanDate,
                newMainLoanAutoData.loanCr,
                newMainLoanAutoData.postPrevDate,
              ],
            ];

            //////////////
            //  // Paying Up Loan Begins
            ////////
            const payUpList = [];
            payUpMainLoanProcessing &&
              newMainLoanAutoData !== undefined &&
              payUpList.push(setNewLoanEditDataList(newMainLoanAutoData));
            payUpSoftLoanProcessing &&
              newSoftLoanAutoData !== undefined &&
              payUpList.push(setNewLoanEditDataList(newSoftLoanAutoData));
            payUpOtherLoanProcessing &&
              newOtherLoanAutoData !== undefined &&
              payUpList.push(setNewLoanEditDataList(newOtherLoanAutoData));
            payUpFoodLoanProcessing &&
              newFoodLoanAutoData !== undefined &&
              payUpList.push(setNewLoanEditDataList(newFoodLoanAutoData));
            payUpWelfareLoanProcessing &&
              newWelfareLoanAutoData !== undefined &&
              payUpList.push(setNewLoanEditDataList(newWelfareLoanAutoData));
            payUpBuildingLoanProcessing &&
              newBuildingLoanAutoData !== undefined &&
              payUpList.push(setNewLoanEditDataList(newBuildingLoanAutoData));
            payUpEssentialLoanProcessing &&
              newEssentialLoanAutoData !== undefined &&
              payUpList.push(setNewLoanEditDataList(newEssentialLoanAutoData));
            payUpBheLoanProcessing &&
              newBheLoanAutoData !== undefined &&
              payUpList.push(setNewLoanEditDataList(newBheLoanAutoData));
            // Paying Up Loan Ends
            //////////////////////////
            //////// Paying Part Starts
            const payPartList = [];
            payPartMainLoanProcessing &&
              newMainLoanAutoData !== undefined &&
              payPartList.push(setNewLoanEditDataList(newMainLoanAutoData));
            payPartSoftLoanProcessing &&
              newSoftLoanAutoData !== undefined &&
              payPartList.push(setNewLoanEditDataList(newSoftLoanAutoData));
            payPartOtherLoanProcessing &&
              newOtherLoanAutoData !== undefined &&
              payPartList.push(setNewLoanEditDataList(newOtherLoanAutoData));
            payPartFoodLoanProcessing &&
              newFoodLoanAutoData !== undefined &&
              payPartList.push(setNewLoanEditDataList(newFoodLoanAutoData));
            payPartWelfareLoanProcessing &&
              newWelfareLoanAutoData !== undefined &&
              payPartList.push(setNewLoanEditDataList(newWelfareLoanAutoData));
            payPartBuildingLoanProcessing &&
              newBuildingLoanAutoData !== undefined &&
              payPartList.push(setNewLoanEditDataList(newBuildingLoanAutoData));
            payPartEssentialLoanProcessing &&
              newEssentialLoanAutoData !== undefined &&
              payPartList.push(
                setNewLoanEditDataList(newEssentialLoanAutoData)
              );
            payPartBheLoanProcessing &&
              newBheLoanAutoData !== undefined &&
              payPartList.push(setNewLoanEditDataList(newBheLoanAutoData));
            //////// Paying Part Ends

            //////// No Deduction Starts
            const noDedList = [];
            noDedMainLoanProcessing &&
              newMainLoanAutoData !== undefined &&
              noDedList.push(setNewLoanEditDataList(newMainLoanAutoData));
            noDedSoftLoanProcessing &&
              newSoftLoanAutoData !== undefined &&
              noDedList.push(setNewLoanEditDataList(newSoftLoanAutoData));
            noDedOtherLoanProcessing &&
              newOtherLoanAutoData !== undefined &&
              noDedList.push(setNewLoanEditDataList(newOtherLoanAutoData));
            noDedFoodLoanProcessing &&
              newFoodLoanAutoData !== undefined &&
              noDedList.push(setNewLoanEditDataList(newFoodLoanAutoData));
            noDedWelfareLoanProcessing &&
              newWelfareLoanAutoData !== undefined &&
              noDedList.push(setNewLoanEditDataList(newWelfareLoanAutoData));
            noDedBuildingLoanProcessing &&
              newBuildingLoanAutoData !== undefined &&
              noDedList.push(setNewLoanEditDataList(newBuildingLoanAutoData));
            noDedEssentialLoanProcessing &&
              newEssentialLoanAutoData !== undefined &&
              noDedList.push(setNewLoanEditDataList(newEssentialLoanAutoData));
            noDedBheLoanProcessing &&
              newBheLoanAutoData !== undefined &&
              noDedList.push(setNewLoanEditDataList(newBheLoanAutoData)); //////// No Deduction Ends
            // try {
            const postAsyncOp =
              postOp === "edit"
                ? editPostsAsync(postData)
                : newPostsAsync(postData);
            await dispatch(postAsyncOp)
              .then(unwrapResult)
              .then(() => {
                if (
                  ((newMainLoanProcessing || existingMainLoanProcessing) &&
                    newMainLoanAutoData !== undefined) ||
                  ((newSoftLoanProcessing || existingSoftLoanProcessing) &&
                    newSoftLoanAutoData !== undefined) ||
                  ((newFoodLoanProcessing || existingFoodLoanProcessing) &&
                    newFoodLoanAutoData !== undefined) ||
                  ((newWelfareLoanProcessing ||
                    existingWelfareLoanProcessing) &&
                    newWelfareLoanAutoData !== undefined) ||
                  ((newBuildingLoanProcessing ||
                    existingBuildingLoanProcessing) &&
                    newBuildingLoanAutoData !== undefined) ||
                  ((newOtherLoanProcessing || existingOtherLoanProcessing) &&
                    newOtherLoanAutoData !== undefined) ||
                  ((newEssentialLoanProcessing ||
                    existingEssentialLoanProcessing) &&
                    newEssentialLoanAutoData !== undefined) ||
                  ((newBheLoanProcessing || existingBheLoanProcessing) &&
                    newBheLoanAutoData !== undefined)
                ) {
                  dispatch(newLoanEditAsync(bulkLoanValues));
                  // .then(unwrapResult)
                  // .then(() => dispatch(resetNewLoanAutoData()));
                }
                //////
                //Additional Main Loan Processin Begins
                if (
                  additionalLoanProcessing &&
                  newMainLoanAutoData !== undefined
                ) {
                  dispatch(additionalLoanAsyc(additionalData));
                  // .then(unwrapResult)
                  // .then(() =>
                  //   dispatch(updateAdditionalLoanProcessing(false))
                  // );
                }
                //Additional Main Loan Processing Ends

                // Paying Up Loan
                if (
                  payUpMainLoanProcessing ||
                  payUpSoftLoanProcessing ||
                  payUpOtherLoanProcessing ||
                  payUpFoodLoanProcessing ||
                  payUpWelfareLoanProcessing ||
                  payUpBuildingLoanProcessing ||
                  payUpEssentialLoanProcessing ||
                  payUpBheLoanProcessing
                ) {
                  dispatch(payUpAsyc(payUpList));
                  // .then(unwrapResult)
                  // .then(() => {
                  //   dispatch(resetNewLoanAutoData());
                  // });
                }
                // Paypart async begins
                if (
                  payPartMainLoanProcessing ||
                  payPartOtherLoanProcessing ||
                  payPartSoftLoanProcessing ||
                  payPartFoodLoanProcessing ||
                  payPartWelfareLoanProcessing ||
                  payPartBuildingLoanProcessing ||
                  payPartEssentialLoanProcessing ||
                  payPartBheLoanProcessing
                ) {
                  dispatch(parPartAsync(payPartList));
                  // .then(unwrapResult)
                  // .then(() => {
                  //   dispatch(resetNewLoanAutoData());
                  // });
                }
                // Paypart async ends

                // No Deduction Dispatch Starts
                if (
                  noDedMainLoanProcessing ||
                  noDedSoftLoanProcessing ||
                  noDedOtherLoanProcessing ||
                  noDedFoodLoanProcessing ||
                  noDedWelfareLoanProcessing ||
                  noDedBuildingLoanProcessing ||
                  noDedEssentialLoanProcessing ||
                  noDedBheLoanProcessing
                ) {
                  dispatch(noDedAsync(noDedList));
                  // .then(unwrapResult)
                  // .then(() => {
                  //   dispatch(resetNewLoanAutoData());
                  // });
                }
                // No Deduction Dispatch Ends

                // Paying Up Ends
                dispatch(resetNewLoanAutoData());
                dispatch(clearFieldInitialValue());
                setMsg("Post Operation was Successful");
                setOpenSnack(true);
              });
            // } catch (error) {}
            // finally {
            //   setSubmitting(false);
            // }
          }}
        >
          {({ submitForm, isSubmitting, values }) => (
            <Form>
              {isSubmitting && <LinearProgress color="secondary" />}
              <Stack
                spacing={2}
                alignItems="center"
                justifyContent="center"
                style={{ margin: 3 }}
              >
                {status === "failed" && (
                  <Typography
                    color="error"
                    component="h5"
                    style={{
                      border: "solid 1px rgba(236, 91, 91, 0.479) ",
                      paddingTop: 8,
                      paddingBottom: 8,
                      paddingLeft: 18,
                      paddingRight: 18,
                      borderRadius: 10,
                    }}
                  >
                    Error! {error.message}
                  </Typography>
                )}
              </Stack>
              <Box
                sx={{
                  width: "70%",
                  background: "rgba(229,255,239,0.5)",
                  margin: "auto",
                }}
              >
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "panel1"}
                  onChange={handleChangePanel("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Name
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      {formValues.id && cooperatorName(formValues.id)}
                    </Typography>
                    {/* color="error" */}
                    <span style={{ color: "red" }}>
                      <ErrorMessage name="names" />
                    </span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Field
                      component={TextField}
                      fullWidth
                      name="names"
                      type="text"
                      value={formValues.id && cooperatorName(formValues.id)}
                      size="small"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "panel2"}
                  onChange={handleChangePanel("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Date
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      {values.postDate}
                      {/* .toISOString().substring(0,10) */}
                      {/* new Date(values.postDate).toLocaleDateString("pt-PT") */}
                      {/* {new Date().toLocaleString("default", {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                      })} */}
                    </Typography>
                    <span style={{ color: "red" }}>
                      <ErrorMessage name="postDate" />
                    </span>
                    {/* color="error" */}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Field
                      component={TextField}
                      fullWidth
                      name="postDate"
                      type="text"
                      size="small"
                      variant="outlined"
                      disabled={!formValues.id}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyUp={() =>
                        handleUpdateFieldValue("postDate", values.postDate)
                      }
                    />
                  </AccordionDetails>
                </Accordion>
                {/*Particulars*/}
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "panel3"}
                  onChange={handleChangePanel("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel17bh-content"
                    id="panel17bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Particulars
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        {values.postParticuars}{" "}
                      </span>
                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="postParticuars "
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Field
                        component={TextField}
                        fullWidth
                        name="postParticuars"
                        type="text"
                        size="small"
                        label="Particulars"
                        disabled={!formValues.id}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // onKeyUp={debounce(
                        //   () =>
                        //     handleUpdateFieldValue(
                        //       "postParticuars",
                        //       values.postParticuars
                        //     ),
                        //   5000
                        // )}
                        onKeyUp={() =>
                          handleUpdateFieldValue(
                            "postParticuars",
                            values.postParticuars
                          )
                        }
                        onFocus={(e) => e.target.select()}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {/* shares */}
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "panel4"}
                  onChange={handleChangePanel("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Shares
                    </Typography>
                    <Typography
                      sx={{ color: "text.secondary", float: "right" }}
                    >
                      <span style={{ display: "block" }}>
                        <span style={{ color: "maroon" }}>Dr:</span>{" "}
                        {numberFormat(values.sharesDr)}
                        {"  "}
                        <span style={{ color: "maroon" }}>Cr:</span>{" "}
                        {numberFormat(values.sharesCr)}
                        {"  "}
                        <span style={{ color: "maroon" }}>Bal:</span>{" "}
                        {numberFormat(
                          Number(values.sharesBal) +
                            Number(values.sharesCr) -
                            Number(values.sharesDr)
                        )}
                      </span>
                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="sharesDr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="sharesCr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="sharesBal"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Field
                      component={TextField}
                      fullWidth
                      name="sharesDr"
                      type="number"
                      size="small"
                      label="Dr"
                      disabled={!formValues.id}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // onKeyUp={debounce(
                      //   () =>
                      //     handleUpdateFieldValue("sharesDr", values.sharesDr),
                      //   5000
                      // )}

                      onKeyUp={() =>
                        handleUpdateFieldValue("sharesDr", values.sharesDr)
                      }
                      onFocus={(e) => e.target.select()}
                    />
                    &nbsp;
                    {/* <CurrencyFormat
                      customInput={TextField}
                      format="#### #### #### ####"
                    /> */}
                    <Field
                      component={TextField}
                      fullWidth
                      name="sharesCr"
                      type="number"
                      size="small"
                      label="Cr"
                      disabled={!formValues.id}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyUp={() => {
                        handleUpdateFieldValue("sharesCr", values.sharesCr);
                      }}
                      onFocus={(e) => e.target.select()}
                    />
                    &nbsp;
                    <Typography>
                      <Field
                        component={TextField}
                        fullWidth
                        name="sharesBal"
                        type="number"
                        size="small"
                        label="Bal"
                        disabled
                        variant="outlined"
                        value={
                          Number(values.sharesBal) +
                          Number(values.sharesCr) -
                          Number(values.sharesDr)
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {/* savings */}
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "panel5"}
                  onChange={handleChangePanel("panel5")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Savings
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        <span style={{ color: "maroon" }}>Dr:</span>{" "}
                        {numberFormat(values.savingsDr)}
                        {"  "}
                        <span style={{ color: "maroon" }}>Cr:</span>{" "}
                        {numberFormat(values.savingsCr)}
                        {"  "}
                        <span style={{ color: "maroon" }}>Bal:</span>{" "}
                        {numberFormat(
                          Number(values.savingsBal) +
                            Number(values.savingsCr) -
                            Number(values.savingsDr)
                        )}
                      </span>
                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="savingsDr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="savingsCr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="savingsBal"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Field
                      component={TextField}
                      fullWidth
                      name="savingsDr"
                      type="number"
                      size="small"
                      label="Dr"
                      disabled={!formValues.id}
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyUp={() =>
                        handleUpdateFieldValue("savingsDr", values.savingsDr)
                      }
                      onFocus={(e) => e.target.select()}
                    />
                    <Field
                      component={TextField}
                      fullWidth
                      name="savingsCr"
                      type="number"
                      size="small"
                      label="Cr"
                      disabled={!formValues.id}
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyUp={() =>
                        handleUpdateFieldValue("savingsCr", values.savingsCr)
                      }
                      onFocus={(e) => e.target.select()}
                    />
                    <Typography>
                      <Field
                        component={TextField}
                        fullWidth
                        name="savingsBal"
                        type="number"
                        size="small"
                        label="Bal"
                        margin="dense"
                        disabled
                        value={
                          Number(values.savingsBal) +
                          Number(values.savingsCr) -
                          Number(values.savingsDr)
                        }
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {/* RSS */}
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "panel6"}
                  onChange={handleChangePanel("panel6")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Retirement Savings Scheme
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        <span style={{ color: "maroon" }}>Dr:</span>{" "}
                        {numberFormat(values.rssDr)}
                        {"  "}
                        <span style={{ color: "maroon" }}>Cr:</span>{" "}
                        {numberFormat(values.rssCr)}
                        {"  "}
                        <span style={{ color: "maroon" }}>Bal:</span>{" "}
                        {numberFormat(
                          Number(values.rssBal) +
                            Number(values.rssCr) -
                            Number(values.rssDr)
                        )}
                      </span>
                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="rssDr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="rssCr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="rssBal"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Field
                      component={TextField}
                      fullWidth
                      name="rssDr"
                      type="number"
                      size="small"
                      label="Dr"
                      disabled={!formValues.id}
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyUp={() =>
                        handleUpdateFieldValue("rssDr", values.rssDr)
                      }
                      onFocus={(e) => e.target.select()}
                    />

                    <Field
                      component={TextField}
                      fullWidth
                      name="rssCr"
                      type="number"
                      size="small"
                      label="Cr"
                      disabled={!formValues.id}
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyUp={() =>
                        handleUpdateFieldValue("rssCr", values.rssCr)
                      }
                      onFocus={(e) => e.target.select()}
                    />

                    <Typography>
                      <Field
                        component={TextField}
                        fullWidth
                        name="rssBal"
                        type="number"
                        size="small"
                        label="Bal"
                        disabled={!formValues.id}
                        margin="dense"
                        value={
                          Number(values.rssBal) +
                          Number(values.rssCr) -
                          Number(values.rssDr)
                        }
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {/* Special Dep */}
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "panel7"}
                  onChange={handleChangePanel("panel7")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6bh-content"
                    id="panel6bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Special Dep
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        <span style={{ color: "maroon" }}>Dr:</span>{" "}
                        {numberFormat(values.specialDepDr)}
                        {"  "}
                        <span style={{ color: "maroon" }}>Cr:</span>{" "}
                        {numberFormat(values.specialDepCr)}
                        {"  "}
                        <span style={{ color: "maroon" }}>Bal:</span>{" "}
                        {numberFormat(
                          Number(values.specialDepBal) +
                            Number(values.specialDepCr) -
                            Number(values.specialDepDr)
                        )}
                      </span>
                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="specialDepDr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="specialDepCr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="specialDepBal"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Field
                      component={TextField}
                      fullWidth
                      name="specialDepDr"
                      type="number"
                      size="small"
                      label="Dr"
                      disabled={!formValues.id}
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyUp={() =>
                        handleUpdateFieldValue(
                          "specialDepDr",
                          values.specialDepDr
                        )
                      }
                      onFocus={(e) => e.target.select()}
                    />

                    <Field
                      component={TextField}
                      fullWidth
                      name="specialDepCr"
                      type="number"
                      size="small"
                      label="Cr"
                      disabled={!formValues.id}
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyUp={() =>
                        handleUpdateFieldValue(
                          "specialDepCr",
                          values.specialDepCr
                        )
                      }
                      onFocus={(e) => e.target.select()}
                    />

                    <Typography>
                      <Field
                        component={TextField}
                        fullWidth
                        name="specialDepBal"
                        type="number"
                        size="small"
                        label="Bal"
                        disabled={!formValues.id}
                        margin="dense"
                        value={
                          Number(values.specialDepBal) +
                          Number(values.specialDepCr) -
                          Number(values.specialDepDr)
                        }
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {/* Total Asset */}
                <Accordion
                  sx={{ background: "rgba(0,127,128,0.47942927170868344)" }}
                  expanded={expanded === "panel8"}
                  onChange={handleChangePanel("panel8")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel7bh-content"
                    id="panel7bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Total Asset:
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        {numberFormat(values.totalAsset)}
                      </span>

                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="totalAsset"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Field
                        component={TextField}
                        fullWidth
                        name="totalAsset"
                        type="number"
                        size="small"
                        disabled
                        label="Total Asset"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {/* Main Loan */}
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "panel9"}
                  onChange={handleChangePanel("panel9")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel8bh-content"
                    id="panel8bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Main Loan
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        <span style={{ color: "maroon" }}>Dr:</span>{" "}
                        {numberFormat(values.mainLoanDr)}{" "}
                        <span style={{ color: "maroon" }}>Cr:</span>
                        {"  "}
                        {numberFormat(values.mainLoanCr)}
                        {"  "}
                        <span style={{ color: "maroon" }}>Bal:</span>{" "}
                        {numberFormat(
                          Number(values.mainLoanCr) !== 0
                            ? Number(values.mainLoanBal) -
                                Number(values.mainLoanCr)
                            : Number(values.mainLoanDr)
                        )}
                        {"  "}
                        <span style={{ color: "maroon" }}>Int:</span>{" "}
                        {numberFormat(values.mainLoanInt)}
                      </span>
                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="mainLoanDr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="mainLoanCr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="mainLoanBal"
                          style={{ display: "block", margin: 5 }}
                        />
                        <ErrorMessage
                          name="mainLoanInt"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {postOp !== "edit" && (
                      <Suspense fallback={<div>...</div>}>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          justifyContent="center"
                          style={{ marginBottom: 20 }}
                        >
                          <ExistingLoan
                            id={formValues.id}
                            loan={"main"}
                            bal={formValues.mainLoanBal}
                          />
                          <MainLoanAuto id={formValues.id} operation="new" />
                          <MainLoanAuto
                            id={formValues.id}
                            operation="additional"
                          />
                          <PayUp
                            id={formValues.id}
                            loan={"main"}
                            loanPrevBal={formValues.mainLoanPrevBal}
                            LoanDr={formValues.mainLoanDr}
                            loanInt={formValues.mainLoanInt}
                          />
                          <PayPart
                            id={formValues.id}
                            loan="main"
                            loanPrevBal={formValues.mainLoanPrevBal}
                            loanCr={formValues.mainLoanCr}
                          />
                          <NoDeduction
                            id={formValues.id}
                            loan={"main"}
                            loanPrevBal={formValues.mainLoanPrevBal}
                            loanCr={formValues.mainLoanCr}
                          />
                          {Number(formValues.mainLoanBal) ? (
                            <Typography
                              sx={{
                                color: "text.secondary",
                              }}
                            >
                              {loaner(formValues.id, "main") !== undefined &&
                              payUpMainLoanProcessing ? (
                                0
                              ) : (
                                <Tooltip
                                  title="Remaining Payment Period Before Submitting"
                                  placement="top"
                                >
                                  <Badge
                                    badgeContent={toDecimal(
                                      Number(formValues.mainLoanBal) /
                                        Number(formValues.mainLoanCr) +
                                        1,
                                      0
                                    )}
                                    color="secondary"
                                  >
                                    <ScheduleIcon color="action" />
                                  </Badge>
                                </Tooltip>
                              )}
                            </Typography>
                          ) : null}
                        </Stack>
                      </Suspense>
                    )}{" "}
                    <div>
                      <Field
                        component={TextField}
                        fullWidth
                        name="mainLoanDr"
                        type="number"
                        size="small"
                        label="Dr"
                        // disabled={postOp==="new"}
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <Field
                        component={TextField}
                        fullWidth
                        name="mainLoanCr"
                        type="number"
                        size="small"
                        label="Cr"
                        // disabled={postOp==="new"}
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      &nbsp;
                      <Field
                        component={TextField}
                        name="mainLoanPrevBal"
                        type="number"
                        size="small"
                        label="Previuous Bal: "
                        disabled
                        margin="dense"
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      &nbsp;
                      <Field
                        component={TextField}
                        fullWidth
                        name="mainLoanBal"
                        type="number"
                        size="small"
                        label="Bal"
                        disabled={postOp === "new"}
                        margin="dense"
                        variant="outlined"
                        value={
                          Number(values.mainLoanCr) !== 0
                            ? Number(values.mainLoanBal) -
                              Number(values.mainLoanCr)
                            : Number(values.mainLoanDr)
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <Field
                        component={TextField}
                        fullWidth
                        name="mainLoanInt"
                        type="number"
                        size="small"
                        label="Int"
                        disabled={postOp === "new"}
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/* Soft Loan */}
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "panel10"}
                  onChange={handleChangePanel("panel10")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel9bh-content"
                    id="panel9bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Bank Loan
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        <span style={{ color: "maroon" }}>Dr:</span>{" "}
                        {numberFormat(values.softLoanDr)}{" "}
                        <span style={{ color: "maroon" }}>Cr:</span>
                        {"  "}
                        {numberFormat(values.softLoanCr)}
                        {"  "}
                        <span style={{ color: "maroon" }}>Bal:</span>{" "}
                        {numberFormat(
                          Number(values.softLoanCr) !== 0
                            ? Number(values.softLoanBal) -
                                Number(values.softLoanCr)
                            : Number(values.softLoanDr)
                        )}
                        {"  "}
                        <span style={{ color: "maroon" }}>Int:</span>{" "}
                        {numberFormat(values.softLoanInt)}
                      </span>
                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="softLoanDr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="softLoanCr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="softLoanBal"
                          style={{ display: "block", margin: 5 }}
                        />
                        <ErrorMessage
                          name="softLoanInt"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {postOp !== "edit" && (
                      <Suspense fallback={<div>...</div>}>
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="center"
                          style={{ marginBottom: 20 }}
                        >
                          <ExistingLoan
                            id={formValues.id}
                            loan={"soft"}
                            bal={formValues.softLoanBal}
                          />
                          <Loan
                            id={formValues.id}
                            bal={formValues.softLoanBal}
                            loan="soft"
                          />
                          <PayUp
                            id={formValues.id}
                            loan={"soft"}
                            loanPrevBal={formValues.softLoanPrevBal}
                            LoanDr={formValues.softLoanDr}
                            loanInt={formValues.softLoanInt}
                          />
                          <PayPart
                            id={formValues.id}
                            loan="soft"
                            loanPrevBal={formValues.softLoanPrevBal}
                            loanCr={formValues.softLoanCr}
                          />
                          <NoDeduction
                            id={formValues.id}
                            loan={"soft"}
                            loanPrevBal={formValues.softLoanPrevBal}
                            loanCr={formValues.softLoanCr}
                          />
                        </Stack>
                      </Suspense>
                    )}{" "}
                    <div>
                      <Field
                        component={TextField}
                        fullWidth
                        name="softLoanDr"
                        type="number"
                        size="small"
                        label="Dr"
                        // disabled={postOp === "new"}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      &nbsp;
                      <Field
                        component={TextField}
                        fullWidth
                        name="softLoanCr"
                        type="number"
                        size="small"
                        label="Cr"
                        // disabled={postOp === "new"}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      &nbsp;
                      <Field
                        component={TextField}
                        fullWidth
                        name="softLoanBal"
                        type="number"
                        size="small"
                        label="Bal"
                        disabled={postOp === "new"}
                        variant="outlined"
                        value={
                          Number(values.softLoanCr) !== 0
                            ? Number(values.softLoanBal) -
                              Number(values.softLoanCr)
                            : Number(values.softLoanDr)
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      &nbsp;
                      <Field
                        component={TextField}
                        fullWidth
                        name="softLoanInt"
                        type="number"
                        size="small"
                        label="Int"
                        disabled={postOp === "new"}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>{" "}
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "panel11"}
                  onChange={handleChangePanel("panel11")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel10bh-content"
                    id="panel10bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Education Loan
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        <span style={{ color: "maroon" }}>Dr:</span>{" "}
                        {numberFormat(values.otherLoanDr)}{" "}
                        <span style={{ color: "maroon" }}>Cr:</span>
                        {"  "}
                        {numberFormat(values.otherLoanCr)}
                        {"  "}
                        <span style={{ color: "maroon" }}>Bal:</span>{" "}
                        {numberFormat(
                          Number(values.otherLoanCr) !== 0
                            ? Number(values.otherLoanBal) -
                                Number(values.otherLoanCr)
                            : Number(values.otherLoanDr)
                        )}
                        {"  "}
                        <span style={{ color: "maroon" }}>Int:</span>{" "}
                        {numberFormat(values.otherLoanInt)}
                      </span>
                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="otherLoanDr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="otherLoanCr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="otherLoanBal"
                          style={{ display: "block", margin: 5 }}
                        />
                        <ErrorMessage
                          name="otherLoanInt"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {postOp !== "edit" && (
                      <Suspense fallback={<div>...</div>}>
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="center"
                          style={{ marginBottom: 20 }}
                        >
                          <ExistingLoan
                            id={formValues.id}
                            loan={"other"}
                            bal={formValues.otherLoanBal}
                          />
                          <Loan
                            id={formValues.id}
                            bal={formValues.otherLoanBal}
                            loan="other"
                          />
                          <PayUp
                            id={formValues.id}
                            loan={"other"}
                            loanPrevBal={formValues.otherLoanPrevBal}
                            LoanDr={formValues.otherLoanDr}
                            loanInt={formValues.otherLoanInt}
                          />
                          <PayPart
                            id={formValues.id}
                            loan="other"
                            loanPrevBal={formValues.otherLoanPrevBal}
                            loanCr={formValues.otherLoanCr}
                          />
                          <NoDeduction
                            id={formValues.id}
                            loan={"other"}
                            loanPrevBal={formValues.otherLoanPrevBal}
                            loanCr={formValues.otherLoanCr}
                          />
                        </Stack>
                      </Suspense>
                    )}

                    <div>
                      <Field
                        component={TextField}
                        fullWidth
                        name="otherLoanDr"
                        type="number"
                        size="small"
                        label="Dr"
                        // disabled={postOp === "new"}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      &nbsp;
                      <Field
                        component={TextField}
                        fullWidth
                        name="otherLoanCr"
                        type="number"
                        size="small"
                        label="Cr"
                        // disabled={postOp === "new"}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      &nbsp;
                      <Field
                        component={TextField}
                        fullWidth
                        name="otherLoanBal"
                        type="number"
                        size="small"
                        label="Bal"
                        disabled={postOp === "new"}
                        variant="outlined"
                        value={
                          Number(values.otherLoanCr) !== 0
                            ? Number(values.otherLoanBal) -
                              Number(values.otherLoanCr)
                            : Number(values.otherLoanDr)
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      &nbsp;
                      <Field
                        component={TextField}
                        fullWidth
                        name="otherLoanInt"
                        type="number"
                        size="small"
                        label="Int"
                        disabled={postOp === "new"}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>{" "}
                {/* Food Stuff */}
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "pane12"}
                  onChange={handleChangePanel("pane12")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel9bh-content"
                    id="panel9bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Food Stuff
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        <span style={{ color: "maroon" }}>Dr:</span>{" "}
                        {numberFormat(values.foodStuffDr)}{" "}
                        <span style={{ color: "maroon" }}>Cr:</span>
                        {"  "}
                        {numberFormat(values.foodStuffCr)}
                        {"  "}
                        <span style={{ color: "maroon" }}>Bal:</span>{" "}
                        {numberFormat(
                          Number(values.foodStuffCr) !== 0
                            ? Number(values.foodStuffBal) -
                                Number(values.foodStuffCr)
                            : Number(values.foodStuffDr)
                        )}
                        {"  "}
                        <span style={{ color: "maroon" }}>Int:</span>{" "}
                        {numberFormat(values.foodStuffInt)}
                      </span>
                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="foodStuffDr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="foodStuffCr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="foodStuffBal"
                          style={{ display: "block", margin: 5 }}
                        />
                        <ErrorMessage
                          name="foodStuffInt"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {postOp !== "edit" && (
                      <Suspense fallback={<div>...</div>}>
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="center"
                          style={{ marginBottom: 20 }}
                        >
                          <ExistingLoan
                            id={formValues.id}
                            loan={"food"}
                            bal={formValues.foodStuffBal}
                          />
                          <Loan
                            id={formValues.id}
                            bal={formValues.foodStuffBal}
                            loan="food"
                          />
                          <PayUp
                            id={formValues.id}
                            loan={"food"}
                            loanPrevBal={formValues.foodStuffPrevBal}
                            LoanDr={formValues.foodStuffDr}
                            loanInt={formValues.foodStuffInt}
                          />
                          <PayPart
                            id={formValues.id}
                            loan="food"
                            loanPrevBal={formValues.foodStuffPrevBal}
                            loanCr={formValues.foodStuffCr}
                          />
                          <NoDeduction
                            id={formValues.id}
                            loan={"food"}
                            loanPrevBal={formValues.foodStuffPrevBal}
                            loanCr={formValues.foodStuffCr}
                          />
                        </Stack>
                      </Suspense>
                    )}
                    <div>
                      <Field
                        component={TextField}
                        fullWidth
                        name="foodStuffDr"
                        type="number"
                        size="small"
                        label="Dr"
                        // disabled={postOp === "new"}
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <Field
                        component={TextField}
                        fullWidth
                        name="foodStuffCr"
                        type="number"
                        size="small"
                        label="Cr"
                        // disabled={postOp === "new"}
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <Field
                        component={TextField}
                        fullWidth
                        name="foodStuffBal"
                        type="number"
                        size="small"
                        label="Bal"
                        margin="dense"
                        disabled={postOp === "new"}
                        variant="outlined"
                        value={
                          Number(values.foodStuffCr) !== 0
                            ? Number(values.foodStuffBal) -
                              Number(values.foodStuffCr)
                            : Number(values.foodStuffDr)
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <Field
                        component={TextField}
                        fullWidth
                        name="foodStuffInt"
                        type="number"
                        size="small"
                        label="Int"
                        margin="dense"
                        disabled={postOp === "new"}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>{" "}
                {/* Welfare */}
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "pane13"}
                  onChange={handleChangePanel("pane13")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel9bh-content"
                    id="panel9bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Welfare
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        <span style={{ color: "maroon" }}>Dr:</span>{" "}
                        {numberFormat(values.welfareDr)}{" "}
                        <span style={{ color: "maroon" }}>Cr:</span>
                        {"  "}
                        {numberFormat(values.welfareCr)}
                        {"  "}
                        <span style={{ color: "maroon" }}>Bal:</span>{" "}
                        {numberFormat(
                          Number(values.welfareCr) !== 0
                            ? Number(values.welfareBal) -
                                Number(values.welfareCr)
                            : Number(values.welfareDr)
                        )}
                        {"  "}
                        <span style={{ color: "maroon" }}>Int:</span>{" "}
                        {numberFormat(values.welfareInt)}
                      </span>
                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="welfareDr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="welfareCr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="welfareBal"
                          style={{ display: "block", margin: 5 }}
                        />
                        <ErrorMessage
                          name="welfareInt"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {postOp !== "edit" && (
                      <Suspense fallback={<div>...</div>}>
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="center"
                          style={{ marginBottom: 20 }}
                        >
                          <ExistingLoan id={formValues.id} loan={"welfare"} />
                          <Loan
                            id={formValues.id}
                            bal={formValues.welfareBal}
                            loan="welfare"
                          />
                          <PayUp
                            id={formValues.id}
                            loan={"welfare"}
                            loanPrevBal={formValues.welfarePrevBal}
                            LoanDr={formValues.welfareDr}
                            loanInt={formValues.welfareInt}
                          />
                          <PayPart
                            id={formValues.id}
                            loan="welfare"
                            loanPrevBal={formValues.welfarePrevBal}
                            loanCr={formValues.welfareCr}
                          />
                          <NoDeduction
                            id={formValues.id}
                            loan={"welfare"}
                            loanPrevBal={formValues.welfarePrevBal}
                            loanCr={formValues.welfareCr}
                          />
                        </Stack>
                      </Suspense>
                    )}
                    <div>
                      <Field
                        component={TextField}
                        fullWidth
                        name="welfareDr"
                        type="number"
                        size="small"
                        label="Dr"
                        // disabled={postOp === "new"}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <Field
                        component={TextField}
                        fullWidth
                        name="welfareCr"
                        type="number"
                        size="small"
                        label="Cr"
                        // disabled={postOp === "new"}
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <Field
                        component={TextField}
                        fullWidth
                        name="welfareBal"
                        type="number"
                        size="small"
                        label="Bal"
                        margin="dense"
                        disabled={postOp === "new"}
                        variant="outlined"
                        value={
                          Number(values.welfareCr) !== 0
                            ? Number(values.welfareBal) -
                              Number(values.welfareCr)
                            : Number(values.welfareDr)
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <Field
                        component={TextField}
                        fullWidth
                        name="welfareInt"
                        type="number"
                        size="small"
                        label="Int"
                        margin="dense"
                        disabled={postOp === "new"}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>{" "}
                {/* Other Loan */}
                {/* Building Fund */}
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "panel14"}
                  onChange={handleChangePanel("panel14")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel11bh-content"
                    id="panel11bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Building Fund
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        <span style={{ color: "maroon" }}>Dr:</span>{" "}
                        {numberFormat(values.buildingFundDr)}{" "}
                        <span style={{ color: "maroon" }}>Cr:</span>
                        {"  "}
                        {numberFormat(values.buildingFundCr)}
                        {"  "}
                        <span style={{ color: "maroon" }}>Bal:</span>{" "}
                        {numberFormat(
                          Number(values.buildingFundCr) !== 0
                            ? Number(values.buildingFundBal) -
                                Number(values.buildingFundCr)
                            : Number(values.buildingFundDr)
                        )}
                        {"  "}
                        <span style={{ color: "maroon" }}>Int:</span>{" "}
                        {numberFormat(values.buildingFundInt)}
                      </span>
                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="buildingFundDr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="buildingFundCr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="buildingFundBal"
                          style={{ display: "block", margin: 5 }}
                        />
                        <ErrorMessage
                          name="buildingFundInt"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {postOp !== "edit" && (
                      <Suspense fallback={<div>...</div>}>
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="center"
                          style={{ marginBottom: 20 }}
                        >
                          <ExistingLoan id={formValues.id} loan={"building"} />
                          <Loan
                            id={formValues.id}
                            bal={formValues.buildingFundBal}
                            loan="building"
                          />
                          <PayUp
                            id={formValues.id}
                            loan={"building"}
                            loanPrevBal={formValues.buildingFundPrevBal}
                            LoanDr={formValues.buildingFundDr}
                            loanInt={formValues.buildingFundInt}
                          />
                          <PayPart
                            id={formValues.id}
                            loan="building"
                            loanPrevBal={formValues.buildingFundPrevBal}
                            loanCr={formValues.buildingFundCr}
                          />
                          <NoDeduction
                            id={formValues.id}
                            loan={"building"}
                            loanPrevBal={formValues.buildingFundPrevBal}
                            loanCr={formValues.buildingFundCr}
                          />
                        </Stack>
                      </Suspense>
                    )}
                    <div>
                      <Field
                        component={TextField}
                        fullWidth
                        name="buildingFundDr"
                        type="number"
                        size="small"
                        label="Dr"
                        // disabled={postOp === "new"}
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <Field
                        component={TextField}
                        fullWidth
                        name="buildingFundCr"
                        type="number"
                        size="small"
                        label="Cr"
                        // disabled={postOp === "new"}
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <Field
                        component={TextField}
                        fullWidth
                        name="buildingFundBal"
                        type="number"
                        size="small"
                        label="Bal"
                        margin="dense"
                        disabled={postOp === "new"}
                        variant="outlined"
                        value={
                          Number(values.buildingFundCr) !== 0
                            ? Number(values.buildingFundBal) -
                              Number(values.buildingFundCr)
                            : Number(values.buildingFundDr)
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <Field
                        component={TextField}
                        fullWidth
                        name="buildingFundInt"
                        type="number"
                        size="small"
                        label="Int"
                        margin="dense"
                        disabled={postOp === "new"}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/* Essential Commodity */}
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "panel15"}
                  onChange={handleChangePanel("panel15")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel12bh-content"
                    id="panel12bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Essential Commodity
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        <span style={{ color: "maroon" }}>Dr:</span>{" "}
                        {numberFormat(values.essentialCommodityDr)}{" "}
                        <span style={{ color: "maroon" }}>Cr:</span>
                        {"  "}
                        {numberFormat(values.essentialCommodityCr)}
                        {"  "}
                        <span style={{ color: "maroon" }}>Bal:</span>{" "}
                        {numberFormat(
                          Number(values.essentialCommodityCr) !== 0
                            ? Number(values.essentialCommodityBal) -
                                Number(values.essentialCommodityCr)
                            : Number(values.essentialCommodityDr)
                        )}
                        {"  "}
                        <span style={{ color: "maroon" }}>Int:</span>{" "}
                        {numberFormat(values.essentialCommodityInt)}
                      </span>
                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="essentialCommodityDr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="essentialCommodityCr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="essentialCommodityBal"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="essentialCommodityInt"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {postOp !== "edit" && (
                      <Suspense fallback={<div>...</div>}>
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="center"
                          style={{ marginBottom: 20 }}
                        >
                          <ExistingLoan id={formValues.id} loan={"essential"} />
                          <Loan
                            id={formValues.id}
                            bal={formValues.essentialCommodityBal}
                            loan="essential"
                          />
                          <PayUp
                            id={formValues.id}
                            loan={"essential"}
                            loanPrevBal={formValues.essentialCommodityPrevBal}
                            LoanDr={formValues.essentialCommodityDr}
                            loanInt={formValues.essentialCommodityInt}
                          />
                          <PayPart
                            id={formValues.id}
                            loan="essential"
                            loanPrevBal={formValues.essentialCommodityPrevBal}
                            loanCr={formValues.essentialCommodityCr}
                          />
                          <NoDeduction
                            id={formValues.id}
                            loan={"essential"}
                            loanPrevBal={formValues.essentialCommodityPrevBal}
                            loanCr={formValues.essentialCommodityCr}
                          />
                        </Stack>
                      </Suspense>
                    )}
                    <div>
                      <Field
                        component={TextField}
                        fullWidth
                        name="essentialCommodityDr"
                        type="number"
                        size="small"
                        // disabled={postOp === "new"}
                        label="Dr"
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <Field
                        component={TextField}
                        fullWidth
                        name="essentialCommodityCr"
                        type="number"
                        size="small"
                        // disabled={postOp === "new"}
                        label="Cr"
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <Field
                        component={TextField}
                        fullWidth
                        name="essentialCommodityBal"
                        type="number"
                        size="small"
                        disabled={postOp === "new"}
                        label="Bal"
                        margin="dense"
                        variant="outlined"
                        value={
                          Number(values.essentialCommodityCr) !== 0
                            ? Number(values.essentialCommodityBal) -
                              Number(values.essentialCommodityCr)
                            : Number(values.essentialCommodityDr)
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <Field
                        component={TextField}
                        fullWidth
                        name="essentialCommodityInt"
                        type="number"
                        size="small"
                        disabled={postOp === "new"}
                        label="Int"
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/* Building / Household Equipment Loan */}
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "panel16"}
                  onChange={handleChangePanel("panel16")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel13bh-content"
                    id="panel13bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Building / Household Equipment Loan
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        <span style={{ color: "maroon" }}>Dr:</span>{" "}
                        {numberFormat(values.bheLoanDr)}{" "}
                        <span style={{ color: "maroon" }}>Cr:</span>
                        {"  "}
                        {numberFormat(values.bheLoanCr)}
                        {"  "}
                        <span style={{ color: "maroon" }}>Bal:</span>{" "}
                        {numberFormat(
                          Number(values.bheLoanCr) !== 0
                            ? Number(values.bheLoanBal) -
                                Number(values.bheLoanCr)
                            : Number(values.bheLoanDr)
                        )}
                        {"  "}
                        <span style={{ color: "maroon" }}>Int:</span>{" "}
                        {numberFormat(values.bheLoanInt)}
                      </span>
                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="bheLoanDr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="bheLoanCr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="bheLoanBal"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="bheLoanInt"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {postOp !== "edit" && (
                      <Suspense fallback={<div>...</div>}>
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="center"
                          style={{ marginBottom: 20 }}
                        >
                          <ExistingLoan id={formValues.id} loan={"bhe"} />
                          <Loan
                            id={formValues.id}
                            bal={formValues.bheLoanBal}
                            loan="bhe"
                          />
                          <PayUp
                            id={formValues.id}
                            loan={"bhe"}
                            loanPrevBal={formValues.bheLoanPrevBal}
                            LoanDr={formValues.bheLoanDr}
                            loanInt={formValues.bheLoanInt}
                          />
                          <PayPart
                            id={formValues.id}
                            loan="bhe"
                            loanPrevBal={formValues.bheLoanPrevBal}
                            loanCr={formValues.bheLoanCr}
                          />
                          <NoDeduction
                            id={formValues.id}
                            loan={"bhe"}
                            loanPrevBal={formValues.bheLoanPrevBal}
                            loanCr={formValues.bheLoanCr}
                          />
                        </Stack>
                      </Suspense>
                    )}
                    <Typography>
                      <Field
                        component={TextField}
                        fullWidth
                        name="bheLoanDr"
                        type="number"
                        size="small"
                        // disabled={postOp === "new"}
                        label="Dr"
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <Field
                        component={TextField}
                        fullWidth
                        name="bheLoanCr"
                        type="number"
                        size="small"
                        // disabled={postOp === "new"}
                        label="Cr"
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <Field
                        component={TextField}
                        fullWidth
                        name="bheLoanBal"
                        type="number"
                        size="small"
                        disabled={postOp === "new"}
                        label="Bal"
                        margin="dense"
                        // disabled={postOp==="new"}="true"
                        variant="outlined"
                        value={
                          Number(values.bheLoanCr) !== 0
                            ? Number(values.bheLoanBal) -
                              Number(values.bheLoanCr)
                            : Number(values.bheLoanDr)
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <Field
                        component={TextField}
                        fullWidth
                        name="bheLoanInt"
                        type="number"
                        size="small"
                        disabled={postOp === "new"}
                        label="Int"
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {/* Admin Fees / Charges */}
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "panel17"}
                  onChange={handleChangePanel("panel17")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel14bh-content"
                    id="panel14bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Admin Fees / Charges
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        <span style={{ color: "maroon" }}>Dr:</span>{" "}
                        {numberFormat(values.adminFessChargesDr)}{" "}
                        <span style={{ color: "maroon" }}>Cr:</span>
                        {"  "}
                        {numberFormat(values.adminFessChargesCr)}
                        {"  "}
                        <span style={{ color: "maroon" }}>Bal:</span>{" "}
                        {numberFormat(
                          Number(values.adminFessChargesBal) +
                            Number(values.adminFessChargesCr) -
                            Number(values.adminFessChargesDr)
                        )}
                      </span>
                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="adminFessChargesDr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="adminFessChargesCr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                        <ErrorMessage
                          name="adminFessChargesBal"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <Field
                        component={TextField}
                        fullWidth
                        name="adminFessChargesDr"
                        type="number"
                        size="small"
                        label="Dr"
                        disabled={!formValues.id}
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onKeyUp={() =>
                          handleUpdateFieldValue(
                            "adminFessChargesDr",
                            values.adminFessChargesDr
                          )
                        }
                      />

                      <Field
                        component={TextField}
                        fullWidth
                        name="adminFessChargesCr"
                        type="number"
                        size="small"
                        label="Cr"
                        disabled={!formValues.id}
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <Field
                        component={TextField}
                        fullWidth
                        name="adminFessChargesBal"
                        type="number"
                        size="small"
                        label="Bal"
                        margin="dense"
                        value={
                          Number(values.adminFessChargesBal) +
                          Number(values.adminFessChargesCr) -
                          Number(values.adminFessChargesDr)
                        }
                        disabled={!formValues.id}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/*Development Levy*/}
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "panel18"}
                  onChange={handleChangePanel("panel18")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel15bh-content"
                    id="panel15bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Development Levy
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        <span style={{ color: "maroon" }}>Cr:</span>{" "}
                        {numberFormat(Number(values.developmentLevyCr))}
                      </span>
                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="developmentLevyCr"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ ml: 2 }}>
                      <Field
                        component={TextField}
                        fullWidth
                        name="developmentLevyCr"
                        type="number"
                        size="small"
                        label="Cr"
                        disabled={!formValues.id}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {/*Entrance Reg Fee*/}
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "panel19"}
                  onChange={handleChangePanel("panel19")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel16bh-content"
                    id="panel16bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Entrance Reg Fee
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        <span style={{ color: "maroon" }}>Cr:</span>{" "}
                        {numberFormat(Number(values.entranceRegFeeCr))}
                      </span>
                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="entranceRegFeeCr "
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Field
                        component={TextField}
                        fullWidth
                        name="entranceRegFeeCr"
                        type="number"
                        size="small"
                        label="Cr"
                        disabled={!formValues.id}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {/*Total Deductions*/}
                <Accordion
                  sx={{ background: "rgba(0,127,128,0.47942927170868344)" }}
                  expanded={expanded === "panel20"}
                  onChange={handleChangePanel("panel20")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel17bh-content"
                    id="panel17bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Total Deductions:
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        {numberFormat(values.totalDeductions)}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Field
                        component={TextField}
                        fullWidth
                        name="totalDeductions"
                        type="number"
                        size="small"
                        disabled
                        label="Total Deductions"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {/*Transaction Posted By*/}
                <Accordion
                  sx={{ background: "rgba(229,255,239,0.5)" }}
                  expanded={expanded === "panel21"}
                  onChange={handleChangePanel("panel21")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel18bh-content"
                    id="panel18bh-header"
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        fontWeight: "bold",
                        color: "#0C2340",
                      }}
                    >
                      Transaction Posted By :
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }}>
                      <span style={{ display: "block" }}>
                        {values.postedBy}{" "}
                      </span>
                      <span style={{ color: "red", display: "block" }}>
                        <ErrorMessage
                          name="postedBy"
                          style={{ display: "block", margin: 5 }}
                        />{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Field
                        component={TextField}
                        fullWidth
                        name="postedBy"
                        type="text"
                        size="small"
                        label="Posted By"
                        disabled={!formValues.id}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onKeyUp={() =>
                          handleUpdateFieldValue("postedBy", values.postedBy)
                        }
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Paper elevation={2}>
                  <Stack
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    style={{ margin: 3 }}
                  >
                    {status === "failed" && (
                      <Typography
                        color="error"
                        component="h5"
                        style={{
                          border: "solid 1px rgba(236, 91, 91, 0.479) ",
                          paddingTop: 8,
                          paddingBottom: 8,
                          paddingLeft: 18,
                          paddingRight: 18,
                          borderRadius: 10,
                        }}
                      >
                        Error! {error.message}
                      </Typography>
                    )}
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    style={{ margin: 10 }}
                  >
                    {isSubmitting && <CircularProgress color="secondary" />}
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                      startIcon={isSubmitting ? "" : <CloudUploadIcon />}
                      style={{
                        margin: 20,
                        background: "#0C2340",
                        paddingLeft: "40px",
                        paddingRight: "40px",
                        textTransform: "none",
                      }}
                    >
                      {isSubmitting ? (
                        <span style={{ color: "yellow" }}>... submitting</span>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                    <IconButton
                      variant="contained"
                      color="secondary"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <KeyboardArrowUpIcon color="primary" />
                    </IconButton>
                  </Stack>

                  <div>
                    <span style={{ color: "red" }}>
                      <ErrorMessage name="postDate" />
                    </span>{" "}
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="postParticuars"
                        style={{ display: "block", margin: 5 }}
                      />
                    </span>{" "}
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="sharesDr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="sharesCr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="sharesBal"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="savingsDr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="savingsCr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="savingsBal"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="rssDr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="rssCr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="rssBal"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="specialDepDr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="specialDepCr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="specialDepBal"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="totalAsset"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="mainLoanDr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="mainLoanCr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="mainLoanBal"
                        style={{ display: "block", margin: 5 }}
                      />
                      <ErrorMessage
                        name="mainLoanInt"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="softLoanDr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="softLoanCr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="softLoanBal"
                        style={{ display: "block", margin: 5 }}
                      />
                      <ErrorMessage
                        name="softLoanInt"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="foodStuffDr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="foodStuffCr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="foodStuffBal"
                        style={{ display: "block", margin: 5 }}
                      />
                      <ErrorMessage
                        name="foodStuffInt"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="welfareDr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="welfareCr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="welfareBal"
                        style={{ display: "block", margin: 5 }}
                      />
                      <ErrorMessage
                        name="welfareInt"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="otherLoanDr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="otherLoanCr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="otherLoanBal"
                        style={{ display: "block", margin: 5 }}
                      />
                      <ErrorMessage
                        name="otherLoanInt"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="buildingFundDr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="buildingFundCr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="buildingFundBal"
                        style={{ display: "block", margin: 5 }}
                      />
                      <ErrorMessage
                        name="buildingFundInt"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="essentialCommodityDr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="essentialCommodityCr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="essentialCommodityBal"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="bheLoanDr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="bheLoanCr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="bheLoanBal"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="adminFessChargesDr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="adminFessChargesCr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                      <ErrorMessage
                        name="adminFessChargesBal"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="developmentLevyCr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="entranceRegFeeCr"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="totalDeductions"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                    <span style={{ color: "red", display: "block" }}>
                      <ErrorMessage
                        name="postedBy"
                        style={{ display: "block", margin: 5 }}
                      />{" "}
                    </span>
                  </div>
                </Paper>
              </Box>
              <GetFormikPropsFromContext />
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}
export default PostInner;
