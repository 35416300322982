import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
// import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useFetch from "./use-fetch";
import CommentDialog from "./admin-comment-dialog";
import Box from "@mui/material/Box";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function CommentCard({ commentId, comment, userId, commentDate }) {
  const { cooperatorName } = useFetch();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const title = userId && cooperatorName(userId);
  return (
    <Card key={commentId} sx={{ maxWidth: 345, m: 2 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            {title.charAt(0)}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={title}
        subheader={commentDate}
      />
      {/* <CardMedia
        component="img"
        height="194"
        image="/static/images/cards/paella.jpg"
        alt="Paella dish"
      /> */}
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {comment}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Content</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default function ViewComments() {
  const { comments } = useFetch();
  const listOfUnViewedComments = comments
    .filter((comment) => comment.viewed === false)
    .map(({ commentId, comment, userId, commentDate }) => (
      <CommentCard
        commentId={commentId}
        comment={comment}
        userId={userId}
        commentDate={commentDate}
      />
    ));
  const unViewedCommentsUi = (
    <div>
      <h5>New Comments</h5>
      {listOfUnViewedComments}
    </div>
  );
  const listOfViewedComments = comments
    .filter((comment) => comment.viewed === true)
    .map(({ commentId, comment, userId, commentDate }) => (
      <CommentCard
        commentId={commentId}
        comment={comment}
        userId={userId}
        commentDate={commentDate}
      />
    ));
  const viewedCommentsUi = (
    <div>
      {listOfUnViewedComments.length > 0 && <h4>Old Comments</h4>}
      {listOfViewedComments}
    </div>
  );

  return (
    <CommentDialog>
      <Box sx={{ p: 2, m: 2 }}>
        <h3>Comments</h3>
        {listOfUnViewedComments.length > 0 && unViewedCommentsUi}
        {listOfViewedComments.length > 0 && viewedCommentsUi}
      </Box>
    </CommentDialog>
  );
}
